import "./App.css";
import { Maintenance } from "./Components/Maintenance";

function App() {
  return (
    // <FirstView />
    <Maintenance />
  );
}

export default App;
