import React, { useEffect, useState } from "react";
import "./style.scss";

import "./style.scss";

export function Maintenance() {
  const [stars, setStars] = useState([]);

  useEffect(() => {
    const getRandomNumber = (min, max) => {
      return Math.random() * (max - min) + min;
    };

    const generateStars = () => {
      const newStars = [];
      for (let i = 0; i < 250; i++) {
        newStars.push({
          top: getRandomNumber(0, window.innerHeight),
          left: getRandomNumber(0, window.innerWidth),
        });
      }
      setStars(newStars);
    };

    generateStars(); // Générer les étoiles initiales

    const handleResize = () => {
      generateStars(); // Recalculer les positions des étoiles lors du redimensionnement de la fenêtre
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize); // Nettoyer l'écouteur d'événements lors du démontage du composant
    };
  }, []); // Déclencher l'effet uniquement au montage

  return (
    <section
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div id="stars-container">
        {stars.map((star, index) => (
          <div
            key={index}
            className="star"
            style={{ top: star.top, left: star.left }}
          ></div>
        ))}
      </div>
      <div style={{ textAlign: "center" }}>
        <img style={{ width: "60%" }} src="../../rocket3.png" alt="rocket" />
        <h1
          style={{
            fontFamily: "monospace",
            fontSize: "1.5rem",
            fontWeight: "bold",
          }}
        >
          Portfolio en cours de construction ...
        </h1>
      </div>
    </section>
  );
}
